<template>
    <AppWrapper>
        <AppHeader></AppHeader>
        <AppBody>
            <slot v-if="isLoggedIn" />
            <LoadingWidget v-else></LoadingWidget>
        </AppBody>
        <AppFooter></AppFooter>
    </AppWrapper>
</template>

<script setup>
const {isLoggedIn} = storeToRefs(useStoreAuth());

function handleIsLoggedIn () {
    if (isLoggedIn.value === null) return useSeo().buildHeadLoading();
    if (isLoggedIn.value === false) {
        const $config = useRuntimeConfig();
        useSeo().buildHead({
            title: 'Login Required | ' + $config.public.companyName,
            description: 'You must sign in to view this page.',
        });
        return useRouter().replace({name: 'login'});
    }
}

watch(isLoggedIn, handleIsLoggedIn, {immediate: true});
</script>
